<template>
  <v-col class="mt-2">
    <v-col class="shades white pa-6">
      <v-row class="px-4">
        <v-col cols="12" md="12">
          <h3>광고 정보를 입력해주세요.</h3>
        </v-col>
        <v-col cols="12" md="10">
          <div class="label mt-6 mb-2">
            매장을 한 줄로 소개해주세요.<span class="caption ml-1 rouge--text"
              >*필수</span
            >
          </div>
          <v-text-field
            v-model="form.abstraction"
            name="abstraction"
            type="text"
            :rules="[errors.first('abstraction') || !errors.has('abstraction')]"
            placeholder="매장 한 줄 소개"
            @input="checkDone"
            single-line
            outlined
            solo
            flat
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="10">
          <div class="label mb-2">
            매장의 특징과 자랑을 길게 적어주세요.<span
              class="caption ml-1 rouge--text"
              >*필수</span
            >
          </div>
          <v-textarea
            v-model="form.introduction"
            name="introduction"
            type="text"
            :rules="[
              errors.first('introduction') || !errors.has('introduction'),
            ]"
            placeholder="매장 상세 소개"
            @input="checkDone"
            outlined
            solo
            flat
          ></v-textarea>
        </v-col>
      </v-row>
    </v-col>
    <v-col class="shades white pa-6 mt-2">
      <v-row class="px-4">
        <v-col class="mt-6" cols="12" md="10">
          <div class="label mb-2">
            네이버 플레이스 영수증 리뷰 필수 여부를 선택해주세요.
          </div>
          <v-btn-toggle
            v-model="form.receiptReviewRequired"
            @change="checkDone"
            mandatory
          >
            <v-btn
              :value="false"
              active-class="rouge--text text--rouge v-btn--active"
              class="ma-0"
              style="width: 130px"
              outlined
              >아니오</v-btn
            >
            <v-btn
              :value="true"
              active-class="rouge--text text--rouge v-btn--active"
              class="ma-0"
              style="width: 130px"
              outlined
              >예</v-btn
            >
          </v-btn-toggle>
          <div class="receipt-information" v-if="form.receiptReviewRequired">
            영수증 리뷰에 대한 네이버 검열이 강화되어, 매장에서 종이 영수증을 제공하지 않은 경우 영수증 리뷰 작성이 불가합니다.
          </div>
        </v-col>
        <v-col class="mt-6" cols="12" md="12" v-if="form.receiptReviewRequired">
          <div class="label mb-2">
            예약/방문자 리뷰에 공정위 문구 필수 삽입 여부를 선택해주세요.
          </div>
          <v-btn-toggle
            v-model="form.ftcRequired"
            @change="checkDone"
            mandatory
          >
            <v-btn
              :value="true"
              active-class="rouge--text text--rouge v-btn--active"
              class="ma-0"
              style="width: 130px"
              outlined
              >예</v-btn
            >
            <v-btn
              :value="false"
              active-class="rouge--text text--rouge v-btn--active"
              class="ma-0"
              style="width: 130px"
              outlined
              >아니오</v-btn
            >
          </v-btn-toggle>
          <div
            v-if="!form.ftcRequired"
            class="mt-2"
            style="font-size: 10px; color: #bd1328"
          >
            아니오를 선택한 경우에도 영수증 리뷰에 공정위 문구가 포함될 수
            있으며, 등록된 영수증 리뷰에서 공정위 문구 제외 및 삭제 요청은
            불가능합니다.
          </div>
        </v-col>
        <v-col
          cols="12"
          class="my-2"
          v-if="form.receiptReviewRequired && form.ftcRequired"
        >
          <div class="label mt-6 mb-2">
            예약/방문자 리뷰에 들어가는 공정위 문구를 입력해주세요.
          </div>
          <v-text-field
            v-model="form.ftcText"
            name="ftcText"
            type="text"
            v-validate="'min:2'"
            :rules="[errors.first('ftcText') || !errors.has('ftcText')]"
            placeholder="매장으로부터 할인받고 작성한 솔직한 후기입니다."
            persistent-hint
            @input="checkDone"
            single-line
            outlined
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="12" class="my-2" v-if="form.receiptReviewRequired">
          <div class="label mt-6 mb-2">
            네이버 플레이스 링크를 입력해주세요.
            <a @click="showGuide = true" style="text-decoration: underline"
              >플레이스 링크를 가져오는 방법 ></a
            >
          </div>
          <v-text-field
            v-model="form.naverPlaceUrl"
            name="naverPlaceUrl"
            type="text"
            v-validate="'min:2'"
            :rules="[
              errors.first('naverPlaceUrl') || !errors.has('naverPlaceUrl'),
              (v) =>
                v.indexOf('naver.me') === -1 ||
                'URL 형식이 맞지 않습니다. 플레이스 링크를 가져오는 방법을 참고해주세요.',
            ]"
            placeholder="https://naver.me/..."
            persistent-hint
            @input="checkDone"
            single-line
            outlined
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="10" v-if="adsData.type === 'S'">
          <div class="label mb-2 mt-6">
            슈퍼멤버스 회원이 방문할 때, 사전 예약이 필요하신가요?
          </div>
          <v-btn-toggle
            v-model="form.isReservationRequired"
            @change="checkDone"
            mandatory
          >
            <v-btn
              :value="false"
              active-class="rouge--text text--rouge v-btn--active"
              class="ma-0"
              style="width: 130px"
              outlined
              flat
              >아니오</v-btn
            >
            <v-btn
              :value="true"
              active-class="rouge--text text--rouge v-btn--active"
              class="ma-0"
              style="width: 130px"
              outlined
              flat
              >예</v-btn
            >
          </v-btn-toggle>
        </v-col>
        <v-col class="my-0" v-if="adsData.type === 'S' && form.isReservationRequired" cols="12" md="12">
          <span class="caption rouge--text"
            >예약 없이 즉시 방문이 가능한 경우 회원의 방문율이 더 높아질 수
            있습니다.</span
          >
        </v-col>
        <v-col cols="12" md="10">
          <div class="label mt-6 mb-2">
            예약 및 방문과 관련하여 주의사항이 있다면 알려주세요.
          </div>
          <v-text-field
            v-model="form.reservationComment"
            name="reservationComment"
            type="text"
            v-validate="'min:2'"
            :rules="[
              errors.first('reservationComment') ||
                !errors.has('reservationComment'),
            ]"
            :hint="
              form.isReservationRequired
                ? '예) 슈퍼멤버스 회원으로 전화예약 후 방문 부탁드립니다. (당일예약은 매장 상황에 따라 어려울 수도 있습니다.)'
                : '예)예약 없이 방문이 가능하나 웨이팅이 있을 수도 있습니다.'
            "
            persistent-hint
            @input="checkDone"
            single-line
            outlined
            solo
            flat
          ></v-text-field>
        </v-col>
        <!--        <v-col v-if="form.isReservationRequired" cols="12" md="12" my-2>-->
        <!--          <v-row>-->
        <!--            <v-col cols="12" md="8" class="estimate">-->
        <!--              <bulbIcon class="light_bulb"/>-->
        <!--              <div>-->
        <!--                예약 없이 당일 현장 방문이 가능한 경우 쉽게 방문할 수 있어,<br>-->
        <!--                예약이 필요한 경우보다 방문율이 더 높아질 수 있습니다.-->
        <!--              </div>-->
        <!--            </v-col>-->
        <!--          </v-row>-->
        <!--        </v-col>-->
        <div class="line my-6"></div>
        <v-col class="mt-2" cols="12" md="10">
          <div class="label mb-2">
            슈퍼멤버스 회원이 방문할 때, 이용할 수 있는 요일이나 시간을 제한하고
            싶으신가요?
          </div>
          <v-btn-toggle v-model="limitation" @change="checkDone" mandatory>
            <v-btn
              :value="false"
              active-class="rouge--text text--rouge v-btn--active"
              class="ma-0"
              style="width: 130px"
              outlined
              flat
              >아니오</v-btn
            >
            <v-btn
              :value="true"
              active-class="rouge--text text--rouge v-btn--active"
              class="ma-0"
              style="width: 130px"
              outlined
              flat
              >예</v-btn
            >
          </v-btn-toggle>
        </v-col>
        <v-col class="my-0" v-if="limitation" cols="12" md="12">
          <span class="caption rouge--text"
            >예약 없이 즉시 방문이 가능한 경우 회원의 방문율이 더 높아질 수
            있습니다.</span
          >
        </v-col>
        <v-col v-if="limitation" cols="12" md="10">
          <div class="label mt-6 mb-2">
            이용 요일 및 시간의 제한이 적을수록 방문율이 더 높아질 수 있습니다.
          </div>
          <v-text-field
            v-model="form.unavailable"
            name="unavailable"
            type="text"
            :rules="[errors.first('unavailable') || !errors.has('unavailable')]"
            hint="예1) 금, 토 방문 불가, 예2) 12:00~13:30 시간대 방문 불가"
            persistent-hint
            @change="checkDone"
            single-line
            outlined
            solo
            flat
          ></v-text-field>
        </v-col>
        <!--        <v-col v-if="limitation" cols="12" md="12" my-2>-->
        <!--          <v-row>-->
        <!--            <v-col cols="12" md="8" class="estimate">-->
        <!--              <bulbIcon class="light_bulb"/>-->
        <!--              &lt;!&ndash;<img src="@/assets/img/regads/light-bulb.svg" class="light_bulb">&ndash;&gt;-->
        <!--              <div>-->
        <!--                이용 요일 및 시간의 제한이 적을수록<br>-->
        <!--                슈퍼멤버스 회원의 방문율이 더 높아질 수 있습니다.-->
        <!--              </div>-->
        <!--            </v-col>-->
        <!--          </v-row>-->
        <!--        </v-col>-->
      </v-row>
    </v-col>
    <v-row justify="center">
      <NaverPlaceGuide v-model="showGuide"></NaverPlaceGuide>
    </v-row>
  </v-col>
</template>
<script>
import { EventBus } from '@/components/shared/event-bus';
import NaverPlaceGuide from '@/components/campaign/dialog/NaverPlaceGuide';

export default {
  name: 'Page3',
  props: ['adsData', 'option'],
  components: {
    NaverPlaceGuide,
  },
  data: function () {
    return {
      form: {
        abstraction: this.adsData.abstraction || '',
        introduction: this.adsData.introduction || '',
        isReservationRequired: this.adsData.type === 'A' || this.adsData.isReservationRequired || false,
        reservationComment: this.adsData.reservationComment || undefined,
        receiptReviewRequired: this.adsData.receiptReviewRequired || false,
        ftcRequired: this.adsData.ftcRequired || true,
        ftcText:
          this.adsData.ftcText ||
          '매장으로부터 할인받고 작성한 솔직한 후기입니다.',
        naverPlaceUrl: this.adsData.naverPlaceUrl || ''
      },
      limitation: false,
      showGuide: false,
    };
  },
  methods: {
    checkDone() {
      this.$validator.validateAll().then((success) => {
        if (success && this.adsData.step === 3) {
          this.form.step = 3;
          this.$emit('updateEvent', this.form);
        }
      });
    },
  },
  watch: {
    'adsData.type': function() {
      if(this.adsData.type === 'A'){
        this.form.isReservationRequired = true;
      }
    }
  },
  created() {
    // this.checkDone();
    EventBus.$on('update:ads', async () => {
      this._.merge(
        this.form,
        this._.pick(this.adsData, [
          'abstraction',
          'introduction',
          'isReservationRequired',
          'reservationComment',
          'receiptReviewRequired',
          'ftcRequired',
          'ftcText',
          'naverPlaceUrl',
        ])
      );
    });
  },
};
</script>
<style scoped lang="scss">
@import '../../../../assets/scss/custom';

.receipt-information{
  color: #BD1328;
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
  margin-top: 8px;
}
</style>
